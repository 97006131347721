import { getMenuElementsPanelData } from './elementsData';
import { MENUS_ELEMENTS_PANEL_ARTICLE_ID } from 'root/utils/consts';
import { MULTIPLE_ROLES_REPRESENTATION, ROLES_DEPENDENCIES } from './consts';
import type { ELEMENT_DATA_BLACK_LIST_MAP } from './consts';
import type {
  ComponentRef,
  ElementCategoryData,
  ElementData as PlatformElementData,
  RemoveComponentHandler,
  AddComponentHandler,
} from '@wix/platform-editor-sdk';
import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { getMenusComponentRef } from './utils';
import { getCompByRoles, getRoleByCompRef, hideComp, showComp } from 'root/utils/showHideUtils';
import { PRICE_VARIANTS_WIDGET_COMPONENT_IDS } from 'root/components/Varaint/consts';
import { getRole } from 'root/utils/manifestUtils';
import { ITEMS_WIDGET_COMPONENT_IDS } from 'root/components/Items/consts';
import { setWidgetProps } from 'root/utils/commonUtils';
import { ELEMENTS_PANEL_ACTION_CHECK, ELEMENTS_PANEL_ACTION_UNCHECK } from 'root/utils/bi';
import type { ElementsPanelAction, ElementData } from 'root/types';

const openElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  categoriesData: ElementCategoryData[],
  elementsData: ElementData[],
  onElementChecked: (action: ElementsPanelAction, elementName: string) => void,
  helpId: string
) => {
  const togglePriceCurrency = async ({
    compRef,
    shouldDisplayCurrency,
  }: {
    compRef: ComponentRef;
    shouldDisplayCurrency: boolean;
  }) => {
    const menusComponentRef = await getMenusComponentRef(compRef, editorSDK.document);

    if (menusComponentRef) {
      await setWidgetProps(editorSDK, menusComponentRef, { shouldDisplayCurrency });
    }
  };

  const toggleVariantCurrency = async ({
    compRef,
    shouldDisplayVariantCurrency,
  }: {
    compRef: ComponentRef;
    shouldDisplayVariantCurrency: boolean;
  }) => {
    const menusComponentRef = await getMenusComponentRef(compRef, editorSDK.document);

    if (menusComponentRef) {
      await setWidgetProps(editorSDK, menusComponentRef, { shouldDisplayVariantCurrency });
    }
  };

  const onShowComp: AddComponentHandler = async ({ role }, compRef) => {
    const multipleRolesRepresentation = MULTIPLE_ROLES_REPRESENTATION[role];
    let componentsToShow;
    if (multipleRolesRepresentation) {
      componentsToShow = await getCompByRoles(editorSDK, widgetRef, multipleRolesRepresentation);
      const dependentRoles = ROLES_DEPENDENCIES[role];
      if (dependentRoles) {
        const dependentRef = await getCompByRoles(editorSDK, widgetRef, [dependentRoles.dependentRole]);
        const isDependentCollapse = await editorSDK.components.refComponents.isRefComponentCollapsed('', {
          componentRef: dependentRef[0].componentRef,
        });

        if (isDependentCollapse) {
          const parentRef = await getCompByRoles(editorSDK, widgetRef, [dependentRoles.parentRole]);
          parentRef && (componentsToShow = [...componentsToShow, ...parentRef]);
        }
      }
    } else {
      componentsToShow = await getCompByRoles(editorSDK, widgetRef, [role]);
    }

    if (compRef && role === getRole(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency)) {
      togglePriceCurrency({ compRef, shouldDisplayCurrency: true });
    }

    if (compRef && role === getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantCurrency)) {
      toggleVariantCurrency({ compRef, shouldDisplayVariantCurrency: true });
    }
    onElementChecked(ELEMENTS_PANEL_ACTION_CHECK, role);

    await Promise.all(componentsToShow.map(({ componentRef }) => showComp(editorSDK, componentRef)));
  };

  const onHideComp: RemoveComponentHandler = async (compRef, elementIdentifier) => {
    let componentsToHide;
    let roles;
    if (MULTIPLE_ROLES_REPRESENTATION[elementIdentifier.role]) {
      roles = MULTIPLE_ROLES_REPRESENTATION[elementIdentifier.role];
      componentsToHide = await getCompByRoles(editorSDK, widgetRef, roles as string[]);
      const dependentRoles = ROLES_DEPENDENCIES[elementIdentifier.role];
      if (dependentRoles) {
        const dependentRef = await getCompByRoles(editorSDK, widgetRef, [dependentRoles.dependentRole]);
        const isDependentCollapse = await editorSDK.components.refComponents.isRefComponentCollapsed('', {
          componentRef: dependentRef[0].componentRef,
        });
        if (isDependentCollapse) {
          const parentRef = await getCompByRoles(editorSDK, widgetRef, [dependentRoles.parentRole]);
          parentRef && (componentsToHide = [...componentsToHide, ...parentRef]);
        }
      }
    } else {
      roles = await getRoleByCompRef(editorSDK, compRef).then((role) => [role]);
      componentsToHide = await getCompByRoles(editorSDK, widgetRef, roles as string[]);
    }

    if (compRef && elementIdentifier.role === getRole(ITEMS_WIDGET_COMPONENT_IDS.itemCurrency)) {
      togglePriceCurrency({ compRef, shouldDisplayCurrency: false });
    }

    if (compRef && elementIdentifier.role === getRole(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantCurrency)) {
      toggleVariantCurrency({ compRef, shouldDisplayVariantCurrency: false });
    }

    const elementName = roles[0] as string;
    onElementChecked(ELEMENTS_PANEL_ACTION_UNCHECK, elementName);

    componentsToHide.forEach(({ componentRef }) => {
      hideComp(editorSDK, componentRef);
    });
  };

  await editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData,
    elementsData: elementsData as PlatformElementData[],
    addComponentHandler: onShowComp,
    removeComponentHandler: onHideComp,
    helpId,
  });
};

export const openMenuElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  onElementChecked: (action: ElementsPanelAction, elementName: string) => void,
  onPanelOpened: () => void,
  preset?: keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
  isLabelLayouterWidgetExperimentEnabled?: boolean,
  isReplaceLabelElementsExperimentEnabled?: boolean,
  isConvertNavigationBarToWidgetExperimentEnabled?: boolean,
  isLabelsTooltipExperimentEnabled?: boolean
) => {
  const { categoriesData, elementsData } = getMenuElementsPanelData(
    t,
    preset,
    isLabelLayouterWidgetExperimentEnabled,
    isReplaceLabelElementsExperimentEnabled,
    isConvertNavigationBarToWidgetExperimentEnabled,
    isLabelsTooltipExperimentEnabled
  );
  await openElementsPanel(
    editorSDK,
    widgetRef,
    categoriesData,
    elementsData,
    onElementChecked,
    MENUS_ELEMENTS_PANEL_ARTICLE_ID
  );
  onPanelOpened();
};
